<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="loading">
			<a-card>
				<a-row>
					<a-col :span="24" class="col">
						<a-row type="flex">
							<a-col :span="3">
								<a-month-picker
									v-model="attCycle"
									@change="onAttCycleChange"
									:allowClear="false"
									style="width: 90%"
								/>
							</a-col>
							<a-col :span="4">
								<a-tree-select
									@change="handleChange"
									allowClear
									multiple
									:placeholder="l('Company')"
									style="width: 90%"
									:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
									:tree-data="companyTreeList"
									tree-default-expand-all
									v-model="comPanyId"
								/>
							</a-col>
							<a-col :span="4">
								<a-tree-select
									v-model="departmentId"
									allowClear
									style="width: 90%"
									multiple
									:filterTreeNode="searchTree"
									:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
									:tree-data="DepTreeData"
									:placeholder="l('Department')"
									tree-default-expand-all
									@select="SelectDepartmentTree"
									@change="search"
								>
								</a-tree-select>
							</a-col>
							<a-col :span="4">
								<a-select
									show-search
									allowClear
									placeholder="请选择发薪条件"
									option-filter-prop="children"
									style="width: 90%"
									v-model="payConditionsCode"
									@change="search"
								>
									<a-select-option v-for="item in PayConditions" :key="item.itemDetailCode">
										{{ item.itemDetailName }}
									</a-select-option>
								</a-select>
							</a-col>
							<a-col :span="5" offset="4">
								<a-input-search
									style="width: 90%;"
									name="filterText"
									:placeholder="l('SearchWithThreeDot')"
									@search="search"
									enterButton
									v-model="filterText"
								/>
							</a-col>
						</a-row>
					</a-col>
					<a-col :span="24" class="col" style="margin-top: 10px;" v-if="attCycleStartTime && attCycleEndTime">
						考勤周期: {{ attCycleStartTime }}~{{ attCycleEndTime }}
					</a-col>
					<a-col :span="24" class="col" style="margin: 10px 0px;">
						<a-button type="primary" @click="importData" v-if="isGranted('sl_month_clac_item_import')">
							<a-icon type="import" />
							<span>计算项导入</span>
						</a-button>
						<a-button type="primary" @click="exporter" v-if="isGranted('sl_month_clac_item_excel')">
							<a-icon type="download" />
							<span>导出</span>
						</a-button>
						<a-button type="primary" @click="importPersonTax" v-if="isGranted('sl_person_tax_import')">
							<a-icon type="import" />
							<span>个税导入</span>
						</a-button>
						<!--                        薪资核对使用-->
						<!--                        <a-button type="primary" @click="importAllItme" v-if="isGranted('sl_person_tax_import')">-->
						<!--                            <a-icon type="import"/>-->
						<!--                            <span>导入所有计算项</span>-->
						<!--                        </a-button>-->
					</a-col>
					<a-col :span="24" class="col">
						<a-table
							id="table"
							@change="sorterChange"
							:columns="columns"
							:pagination="false"
							bordered
							size="small"
							:scroll="_scroll"
							:dataSource="tableData"
						>
							<span slot="actions" slot-scope="text, record">
								<a-space v-if="isGranted('sl_month_clac_item_edit')">
									<a class="table-edit" @click="edit(record)"><a-icon type="edit" />修改</a>
								</a-space>
							</span>
						</a-table>
						<a-pagination
							class="pagination"
							:total="totalItems"
							v-model="pageNumber"
							showSizeChanger
							showQuickJumper
							:showTotal="showTotalFun"
							@change="onChangePage"
							@showSizeChange="showSizeChange"
						/>
					</a-col>
				</a-row>
			</a-card>
		</a-spin>
	</a-config-provider>
</template>

<script>
import { AppComponentBase } from '../../../shared/component-base';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import moment from 'moment';
import {
	CompanyServiceProxy,
	DepartmentServiceProxy,
	EmpSalaryItemsServiceProxy,
	EnterpriseAttendCycleServiceProxy,
} from '../../../shared/service-proxies';
import Dic from '../../../shared/utils/dic';
import EditMonthCalcItem from './edit-month-calc-item/edit-month-calc-item.vue';
import ImportExcelModel from './import-excel-model/import-excel-model.vue';
import modalHelper from '../../../shared/helpers/modal/modal-helper';
import { fileDownloadService } from '../../../shared/utils';
import DepTreeUntils from '../../organization/department/dep-tree-untils/dep-tree-untils';
import ImportPersonTaxMode from './import-person-tax-model/import-person-tax-model';
import ImportAllItem from './import-all-item/import-all-item';

const columns = [
	{
		title: '工号',
		dataIndex: 'jobNumber',
		sorter: true,
		ellipsis: true,
		align: 'center',
		width: 110,
		fixed: 'left',
	},
	{
		title: '姓名',
		dataIndex: 'realName',
		sorter: true,
		ellipsis: true,
		align: 'center',
		width: 90,
		fixed: 'left',
	},
	{
		title: '公司',
		dataIndex: 'companyName',
		sorter: true,
		width: 110,
		ellipsis: true,
		align: 'center',
	},
	{
		title: '部门',
		dataIndex: 'depantment',
		sorter: true,
		ellipsis: true,
		width: 110,
		align: 'center',
	},
	{
		title: '年份',
		dataIndex: 'slYear',
		sorter: false,
		ellipsis: true,
		align: 'center',
		width: 60,
	},
	{
		title: '月份',
		dataIndex: 'slMonth',
		sorter: false,
		ellipsis: true,
		align: 'center',
		width: 60,
	},
	{
		title: '固定项',
		children: [
			{
				title: '标准月薪',
				dataIndex: 'monthlyPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '底薪',
				dataIndex: 'basePay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 70,
			},
			{
				title: '主管加给',
				dataIndex: 'managerAdd',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '岗位津贴',
				dataIndex: 'postAllowance',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '技术津贴',
				dataIndex: 'skillAllowance',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '全勤奖金',
				dataIndex: 'fullAllBonus',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '通讯补贴',
				dataIndex: 'communicationSubsidy',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '保底工资',
				dataIndex: 'guaranteedPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '预付加班工资',
				dataIndex: 'prepaidOtPay',
				sorter: true,
				align: 'center',
				width: 140,
			},
		],
	},
	{
		title: '可变项',
		children: [
			{
				title: '应出勤时数',
				dataIndex: 'stdLoborHours',
				sorter: true,
				align: 'center',
				width: 110,
			},
			{
				title: '实际出勤时数',
				dataIndex: 'factLaborHours',
				sorter: true,
				align: 'center',
				width: 140,
			},
			{ title: '缺勤时数', sorter: true, dataIndex: 'absenceDays', ellipsis: true, align: 'center', width: 140 },
			{
				title: '周末应出勤时数',
				dataIndex: 'weekendStdLoborHours',
				sorter: true,
				align: 'center',
				width: 160,
			},
			{
				title: '周末实际出勤时数',
				dataIndex: 'weekendFactLaborHours',
				sorter: true,
				align: 'center',
				width: 180,
			},
			{
				title: '有薪假时数',
				dataIndex: 'paidLeaveHours',
				sorter: true,
				align: 'center',
				width: 110,
			},
			{
				title: '周末有薪假时数',
				dataIndex: 'weekendPaidLeave',
				sorter: true,
				align: 'center',
				width: 140,
			},
			{
				title: '绩效奖金',
				dataIndex: 'kpiPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: 'EHS奖',
				dataIndex: 'ehsPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '安全奖',
				dataIndex: 'securityPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '内部稽核奖',
				dataIndex: 'auditPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 110,
			},
			{
				title: '技术改善奖',
				dataIndex: 'skillPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 110,
			},
			{
				title: '超产奖',
				dataIndex: 'overProducePay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '内荐奖',
				dataIndex: 'recommendPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '奖惩',
				dataIndex: 'award',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 70,
			},
			{
				title: '其它奖金',
				dataIndex: 'otherBonus',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '夜班天数',
				dataIndex: 'nightShiftDays',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '住宿/交通/餐补',
				dataIndex: 'stayTrafficMealPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 150,
			},
			{
				title: '接机补贴',
				dataIndex: 'airportPickUpPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			// {
			//     title: '油费补贴',
			//     dataIndex: "oilFeeSubsidy",
			//     sorter: true,
			//     ellipsis: true,
			//     align: "center",
			//     width: 90,
			// },
			{
				title: '其它补贴',
				dataIndex: 'otherSubsidy',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '事假时数',
				dataIndex: 'sjHours',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '病假时数',
				dataIndex: 'bjHours',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '周末病假时数',
				dataIndex: 'weekBjHours',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 130,
			},
			{
				title: '旷工时数',
				dataIndex: 'absentHours',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '工作日加班时数',
				dataIndex: 'oT1',
				sorter: true,
				align: 'center',
				width: 140,
			},
			{
				title: '周末加班时数',
				dataIndex: 'oT2',
				sorter: true,
				align: 'center',
				width: 140,
			},
			{
				title: '法定加班时数',
				dataIndex: 'oT3',
				sorter: true,
				align: 'center',
				width: 140,
			},
			{
				title: '现金支付',
				dataIndex: 'cashPayment',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '其它个人借支',
				dataIndex: 'otherPersonalLoan',
				sorter: true,
				align: 'center',
				width: 140,
			},
			{
				title: '个税',
				dataIndex: 'personTax',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 80,
			},
			{
				title: '社保补缴',
				dataIndex: 'ssWithholdingPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 100,
			},
			{
				title: '公积金补缴',
				dataIndex: 'pfWithholdingPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 100,
			},
		],
	},
	{
		title: '操作',
		dataIndex: 'actions',
		align: 'center',
		fixed: 'right',
		scopedSlots: { customRender: 'actions' },
		width: 80,
	},
];

// 薪资月度计算项页面
export default {
	name: 'month-calc-item',
	mixins: [AppComponentBase],
	components: { ImportPersonTaxMode, ImportAllItem },
	data() {
		return {
			zh_CN,
			//表格上方间隔
			defaultTop: 170,
			companyTreeList: [],
			comPanyId: undefined,
			departmentId: undefined,
			//部门树
			DepTreeData: [],
			DepTree: [],
			filterText: '',
			columns,
			tableData: [],
			//发薪条件
			payConditionsCode: undefined,
			PayConditions: [],
			//考勤周期
			attCycle: moment(),
			attCycleStartTime: '',
			attCycleEndTime: '',
			totalItems: 0, //总数
			// 当前页码
			pageNumber: 1,
			// 共多少页
			totalPages: 1,
			// 条数显示范围
			pagerange: [1, 1],
			// 显示条数
			pageSizeOptions: ['10', '20', '30', '40'],
			request: { maxResultCount: 10, skipCount: 0, sorting: '' },
		};
	},
	computed: {
		_scroll() {
			if (this.tableData.length > 0) {
				return { x: 3000, y: this.scroll_y };
			} else {
				return { x: false };
			}
		},
	},
	async created() {
		this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._enterpriseAttendCycleServiceProxy = new EnterpriseAttendCycleServiceProxy(this.$apiUrl, this.$api);
		this._empSalaryItemsServiceProxy = new EmpSalaryItemsServiceProxy(this.$apiUrl, this.$api);
		this._fileDownloadService = fileDownloadService;
		try {
			this.loading = true;
			this.PayConditions = await Dic.getInstance().getDicAsync('EmpTypeKind1');
			await this.companyTreeInit();
			//获取考勤周期
			await this.getCycle('init');
		} catch (e) {
		} finally {
			this.loading = false;
			this.getData();
		}
	},
	methods: {
		refresh() {
			this.pageNumber = 1;
			this.request.skipCount = 0;
			this.getData();
		},
		getData() {
			this.loading = true;
			this._empSalaryItemsServiceProxy
				.getPaged(
					this.attCycle.year(),
					this.attCycle.month() + 1,
					this.comPanyId,
					this.departmentId,
					this.payConditionsCode,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.then((res) => {
					this.tableData = res.items;
					this.totalItems = res.totalCount;
					this.totalPages = Math.ceil(res.totalCount / this.request.maxResultCount);
					this.pagerange = [
						(this.pageNumber - 1) * this.request.maxResultCount + 1,
						this.pageNumber * this.request.maxResultCount,
					];
				})
				.finally((res) => {
					this.loading = false;
				});
		},
		edit(record) {
			modalHelper
				.create(
					EditMonthCalcItem,
					{
						id: record.id,
						jobNumber: record.jobNumber,
						realName: record.realName,
					},
					{ width: '800px' }
				)
				.subscribe((res) => {
					if (res) {
						this.getData();
					}
				});
		},
		// 导入
		importData() {
			modalHelper
				.create(
					ImportExcelModel,
					{
						year: this.attCycle.year(),
						month: this.attCycle.month() + 1,
						attCycleStartTime: this.attCycleStartTime,
						attCycleEndTime: this.attCycleEndTime,
					},
					{ width: '800px' }
				)
				.subscribe((res) => {
					this.getData();
				});
		},
		importAllItme() {
			modalHelper
				.create(
					ImportAllItem,
					{
						year: this.attCycle.year(),
						month: this.attCycle.month() + 1,
						attCycleStartTime: this.attCycleStartTime,
						attCycleEndTime: this.attCycleEndTime,
					},
					{ width: '800px' }
				)
				.subscribe((res) => {
					this.getData();
				});
		},
		/**
		 * 个税导入
		 */
		importPersonTax() {
			modalHelper
				.create(
					ImportPersonTaxMode,
					{
						year: this.attCycle.year(),
						month: this.attCycle.month() + 1,
					},
					{
						width: '800px',
					}
				)
				.subscribe((res) => {
					this.getData();
				});
		},
		async getCycle(type) {
			if (type === 'init') {
				const { startTime, endTime, yearOrMonth } = await this.initCycle();
				this.attCycleStartTime = startTime;
				this.attCycleEndTime = endTime;
				this.attCycle = moment(yearOrMonth);
			} else {
				const { startTime, endTime, yearOrMonth } = await this.getCycleByDate();
				this.attCycleStartTime = startTime;
				this.attCycleEndTime = endTime;
			}
		},
		/**
		 * 初始化考勤周期
		 */
		async initCycle() {
			return new Promise((resolve, reject) => {
				this.loading = true;
				this._enterpriseAttendCycleServiceProxy.getCycleByNow().then((res) => {
					resolve(res);
				});
			});
		},
		/**
		 * 获取指定年月的周期
		 */
		async getCycleByDate() {
			return new Promise((resolve, reject) => {
				this.loading = true;
				this._enterpriseAttendCycleServiceProxy
					.getByTenant(this.attCycle.year(), this.attCycle.month() + 1)
					.then((res) => {
						resolve(res);
					});
			});
		},
		//考勤周期选择
		onAttCycleChange(e) {
			this.attCycle = e;
			this.getCycle();
			this.refresh();
		},
		searchTree(inputValue, treeNode) {
			return treeNode.data.props.title.includes(inputValue);
		},
		companyTreeInit() {
			return new Promise((resolve) => {
				this._CompanyConfigServiceProxy
					.getPaged(undefined, '', 100, 0)
					.finally(() => {
						this.loading = false;
					})
					.then((res) => {
						this.companyList = res.items;
						this.companyList.forEach((item) => {
							if (item.parentId == 0) {
								var obj = {
									key: item.id,
									value: item.id,
									title: item.fullName,
								};
								this.companyTreeList.push(obj);
							}
						});
						this.initCompanyTree(this.companyTreeList);
						resolve();
					});
			});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		handleChange(value) {
			this.comPanyId = value;
			this.departmentId = undefined;
			this.refresh();
			this.getDepTreeData();
		},
		/**
		 * 获取部门
		 */
		getDepTreeData() {
			this.DepTreeData = [];
			this._DepartmentServiceProxy
				.getDepTreeData(this.comPanyId)
				.then((res) => {
					this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
					this.DepTree.forEach((item) => {
						if (!item.parentId) {
							this.DepTreeData.push(item);
						}
					});
					this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		/**
		 * 选择部门
		 */
		SelectDepartmentTree(e) {
			this.departmentId = e;
		},
		/**
		 * 分页事件
		 */
		showTotalFun() {
			return this.l(
				'GridFooterDisplayText',
				this.pageNumber,
				this.totalPages,
				this.totalItems,
				this.pagerange[0],
				this.pagerange[1]
			);
		},
		/**
		 * 分页
		 */
		onChangePage(page, pageSize) {
			this.pageNumber = page;
			this.request.skipCount = (page - 1) * this.request.maxResultCount;
			this.getData();
		},
		showSizeChange(current, size) {
			this.pageNumber = 1;
			this.request.maxResultCount = size;
			this.getData();
		},
		/**
		 * 排序
		 * @param pagination
		 * @param filters
		 * @param sorter
		 */
		sorterChange(pagination, filters, sorter) {
			if (sorter.order) {
				this.request.sorting = sorter.columnKey + ' ' + sorter.order.replace('end', '');
			} else {
				this.request.sorting = undefined;
			}
			this.refresh();
		},
		/**
		 * 导出
		 */
		exporter() {
			if (this.tableData.length <= 0) {
				return abp.message.warn(this.l('NoData'));
			}
			this.loading = true;
			this._empSalaryItemsServiceProxy
				.getToExcelFile(
					this.attCycle.year(),
					this.attCycle.month() + 1,
					this.comPanyId,
					this.departmentId,
					this.payConditionsCode,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this._fileDownloadService.downloadTempFile(res);
				});
		},
	},
};
</script>

<style scoped>
.pagination {
	margin: 10px auto;
	text-align: right;
}
</style>
