<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="loading">
			<!-- 标题 -->
			<div class="modal-header">
				<div class="modal-title">
					<span>{{ realName ? realName : '' }}({{ jobNumber ? jobNumber : '' }})</span>
				</div>
			</div>
			<div>
				<a-row>
					<a-col :span="5">
						<q-label label="奖惩"> </q-label>
					</a-col>
					<a-col :span="7">
						<a-input-number class="width100" step="0.5" v-model="entity.award" />
					</a-col>
					<a-col :span="5">
						<q-label label="其他奖金"> </q-label>
					</a-col>
					<a-col :span="7">
						<a-input-number class="width100" :min="0" step="0.5" v-model="entity.otherBonus" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="5">
						<q-label label="绩效奖金"> </q-label>
					</a-col>
					<a-col :span="7">
						<a-input-number class="width100" :min="0" step="0.5" v-model="entity.kpiPay" />
					</a-col>

					<a-col :span="5">
						<q-label label="EHS奖"> </q-label>
					</a-col>
					<a-col :span="7">
						<a-input-number class="width100" :min="0" step="0.5" v-model="entity.ehsPay" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="5">
						<q-label label="安全奖"> </q-label>
					</a-col>
					<a-col :span="7">
						<a-input-number class="width100" :min="0" step="0.5" v-model="entity.securityPay" />
					</a-col>

					<a-col :span="5">
						<q-label label="内部稽核奖"> </q-label>
					</a-col>
					<a-col :span="7">
						<a-input-number class="width100" :min="0" step="0.5" v-model="entity.auditPay" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="5">
						<q-label label="技术改善奖"> </q-label>
					</a-col>
					<a-col :span="7">
						<a-input-number class="width100" :min="0" step="0.5" v-model="entity.skillPay" />
					</a-col>

					<a-col :span="5">
						<q-label label="超产奖"> </q-label>
					</a-col>
					<a-col :span="7">
						<a-input-number class="width100" :min="0" step="0.5" v-model="entity.overProducePay" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="5">
						<q-label label="内荐奖"> </q-label>
					</a-col>
					<a-col :span="7">
						<a-input-number class="width100" :min="0" step="0.5" v-model="entity.recommendPay" />
					</a-col>

					<a-col :span="5">
						<q-label label="住宿/交通/餐补或油补"> </q-label>
					</a-col>
					<a-col :span="7">
						<a-input-number class="width100" :min="0" step="0.5" v-model="entity.stayTrafficMealPay" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="5">
						<q-label label="接机补贴"> </q-label>
					</a-col>
					<a-col :span="7">
						<a-input-number class="width100" :min="0" step="0.5" v-model="entity.airportPickUpPay" />
					</a-col>
					<a-col :span="5">
						<q-label label="其他补贴"> </q-label>
					</a-col>
					<a-col :span="7">
						<a-input-number class="width100" :min="0" step="0.5" v-model="entity.otherSubsidy" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="5">
						<q-label label="现金支付"> </q-label>
					</a-col>
					<a-col :span="7">
						<a-input-number class="width100" :min="0" step="0.5" v-model="entity.cashPayment" />
					</a-col>
					<a-col :span="5">
						<q-label label="其他个人借支"> </q-label>
					</a-col>
					<a-col :span="7">
						<a-input-number class="width100" :min="0" step="0.5" v-model="entity.otherPersonalLoan" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="5">
						<q-label label="个税"> </q-label>
					</a-col>
					<a-col :span="7">
						<a-input-number class="width100" :min="0" step="0.5" v-model="entity.personTax" />
					</a-col>
					<a-col :span="5">
						<q-label label="社保补缴"> </q-label>
					</a-col>
					<a-col :span="7">
						<a-input-number class="width100" :min="0" step="0.5" v-model="entity.ssWithholdingPay" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="5">
						<q-label label="公积金补缴"> </q-label>
					</a-col>
					<a-col :span="7">
						<a-input-number class="width100" :min="0" step="0.5" v-model="entity.pfWithholdingPay" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="5">
						<q-label label="应出勤时数"> </q-label>
					</a-col>
					<a-col :span="7">
						{{ entity.stdLoborHours ? entity.stdLoborHours : 0 }}
						<!--                        <a-input-number class="width100" :min="0" step="0.5" v-model="entity.stdLoborHours"/>-->
					</a-col>
					<a-col :span="5">
						<q-label label="实际出勤时数"> </q-label>
					</a-col>
					<a-col :span="7">
						{{ entity.factLaborHours ? entity.factLaborHours : 0 }}
						<!--                        <a-input-number class="width100" :min="0" step="0.5" v-model="entity.factLaborHours"/>-->
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="5">
						<q-label label="周末应出勤时数"> </q-label>
					</a-col>
					<a-col :span="7">
						{{ entity.weekendStdLoborHours ? entity.weekendStdLoborHours : 0 }}
						<!--                        <a-input-number class="width100" :min="0" step="0.5" v-model="entity.weekendStdLoborHours"/>-->
					</a-col>
					<a-col :span="5">
						<q-label label="周末实际出勤时数"> </q-label>
					</a-col>
					<a-col :span="7">
						{{ entity.weekendFactLaborHours ? entity.weekendFactLaborHours : 0 }}
						<!--                        <a-input-number class="width100" :min="0" step="0.5" v-model="entity.weekendFactLaborHours"/>-->
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="5">
						<q-label label="有薪假时数"> </q-label>
					</a-col>
					<a-col :span="7">
						{{ entity.paidLeaveHours ? entity.paidLeaveHours : 0 }}
						<!--                        <a-input-number class="width100" :min="0" step="0.5" v-model="entity.paidLeaveHours"/>-->
					</a-col>
					<a-col :span="5">
						<q-label label="夜班天数"> </q-label>
					</a-col>
					<a-col :span="7">
						{{ entity.nightShiftDays ? entity.nightShiftDays : 0 }}
						<!--                        <a-input-number class="width100" :min="0" step="0.5" v-model="entity.nightShiftDays"/>-->
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="5">
						<q-label label="病假时数"> </q-label>
					</a-col>
					<a-col :span="7">
						{{ entity.bjHours ? entity.bjHours : 0 }}
						<!--                        <a-input-number class="width100" :min="0" step="0.5" v-model="entity.bjHours"/>-->
					</a-col>
					<a-col :span="5">
						<q-label label="旷工时数"> </q-label>
					</a-col>
					<a-col :span="7">
						{{ entity.absentHours ? entity.absentHours : 0 }}
						<!--                        <a-input-number class="width100" :min="0" step="0.5" v-model="entity.absentHours"/>-->
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="5">
						<q-label label="工作日加班时数"> </q-label>
					</a-col>
					<a-col :span="7">
						{{ entity.oT1 ? entity.oT1 : 0 }}
						<!--                        <a-input-number class="width100" :min="0" step="0.5" v-model="entity.oT1"/>-->
					</a-col>
					<a-col :span="5">
						<q-label label="周末加班时数"> </q-label>
					</a-col>
					<a-col :span="7">
						{{ entity.oT2 ? entity.oT2 : 0 }}
						<!--                        <a-input-number class="width100" :min="0" step="0.5" v-model="entity.oT2"/>-->
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="5">
						<q-label label="法定加班时数"> </q-label>
					</a-col>
					<a-col :span="7">
						{{ entity.oT3 ? entity.oT3 : 0 }}
						<!--                        <a-input-number class="width100" :min="0" step="0.5" v-model="entity.oT3"/>-->
					</a-col>
					<a-col :span="5">
						<q-label label="事假时数"> </q-label>
					</a-col>
					<a-col :span="7">
						{{ entity.sjHours ? entity.sjHours : 0 }}
						<!--                        <a-input-number class="width100" :min="0" step="0.5" v-model="entity.sjHours"/>-->
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="5">
						<q-label label="周末有薪假时数"> </q-label>
					</a-col>
					<a-col :span="7">
						{{ entity.weekendPaidLeave }}
					</a-col>
				</a-row>
			</div>
			<!-- 按钮 -->
			<div class="modal-footer">
				<a-button @click="close()" type="button">
					<a-icon type="close-circle" />
					{{ l('Cancel') }}
				</a-button>
				<a-button :type="'primary'" @click="save">
					<a-icon type="save" />
					{{ l('Save') }}
				</a-button>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import {
	EmpSalaryItemsServiceProxy,
	EmpSalaryItemsEditDto,
	CreateOrUpdateEmpSalaryItemsInput,
} from '../../../../shared/service-proxies';

export default {
	name: 'edit-month-calc-item',
	mixins: [ModalComponentBase],
	data() {
		return {
			zh_CN,
			id: undefined,
			entity: new EmpSalaryItemsEditDto(),
			realName: undefined,
			jobNumber: undefined,
		};
	},
	created() {
		this.fullData();
		this._empSalaryItemsServiceProxy = new EmpSalaryItemsServiceProxy(this.$apiUrl, this.$api);
		if (this.id) {
			this.loading = true;
			this._empSalaryItemsServiceProxy
				.getForEdit(this.id)
				.then(({ empSalaryItems }) => {
					this.entity = empSalaryItems;
				})
				.finally(() => {
					this.loading = false;
				});
		}
	},
	methods: {
		save() {
			this.loading = true;
			const input = new CreateOrUpdateEmpSalaryItemsInput();
			input.empSalaryItems = this.entity;
			this._empSalaryItemsServiceProxy
				.createOrUpdate(input)
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style scoped>
.width100 {
	width: 100%;
}
</style>
