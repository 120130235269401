<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <!-- 标题 -->
            <div class="modal-header">
                <div class="modal-title">
                    <span>个税导入</span>
                </div>
            </div>
            <div class="my-md">
                <a-row class="text">
                    1.当前月度：<b>{{year}}</b>-<b>{{month}}</b>
                </a-row>
                <a-row class="text">
                    2.其中<b>姓名、证件号码</b>为必填，累计子女教育支出扣除、累计继续教育支出扣除、累计住房贷款利息支出扣除、累计住房租金支出扣除、累计赡养老人支出扣除、累计应补(退)税额为系统维护项
                </a-row>
                <a-row class="text">
                    3.导入文件必须是xls,xlsx格式
                </a-row>
                <a-row class="text">
                    <a-col :span="5">
                        <a-button
                                @click="ExportTemplate()"
                        >
                            <a-icon type="file-excel"/>
                            <span>{{ l("下载模板") }}</span>
                        </a-button>
                    </a-col>
                    <a-col :span="19">
                        <a-upload
                                :action="uploadPictureUrl"
                                :beforeUpload="beforeUpload"
                                :headers="uploadHeaders"
                                @change="uploadPictureChange($event)">
                            <a-button type="primary"> <a-icon type="import" /> 导入 </a-button>
                        </a-upload>
                    </a-col>
                </a-row>
            </div>
        </a-spin>
    </a-config-provider>
</template>

<script>
    import ModalComponentBase from "../../../../shared/component-base/modal-component-base";
    import zh_CN from  'ant-design-vue/lib/locale-provider/zh_CN'
    import {AppConsts} from "../../../../abpPro/AppConsts";
    import {EmpSalaryItemsServiceProxy, ImportExcelInput} from "../../../../shared/service-proxies";
    import {fileDownloadService} from "../../../../shared/utils";
    import moment from "moment";

    export default {
        name: "import-person-tax-model",
        mixins: [ModalComponentBase],
        data(){
            return{
                zh_CN,
                uploadPictureUrl: AppConsts.remoteServiceBaseUrl + "/Profile/UploadExcelFile",
                uploadHeaders: {
                    Authorization: "Bearer " + abp.auth.getToken()
                },
                year: undefined,
                month: undefined,
                attCycleStartTime: undefined,
                attCycleEndTime: undefined
            }
        },
        created() {
            this.fullData();
            this._empSalaryItemsServiceProxy = new EmpSalaryItemsServiceProxy(this.$apiUrl, this.$api)
            this._fileDownloadService = fileDownloadService;
        },
        mounted() {

        },
        methods:{
            beforeUpload(file) {
                let filetype;
                if(file.name){
                    filetype=file.name.substring(file.name.lastIndexOf('.'));
                }
                let isExcel=filetype===".xlsx" ||
                    filetype===".xls";
                if(!isExcel){
                    return abp.message.warn("只能上传xlsx格式和xls的文件");
                }

            },
            uploadPictureChange({file, fileList}) {
                if (file.status === 'done') {
                    let input=new ImportExcelInput();
                    input.year = this.year;
                    input.month = this.month;
                    input.fileName = file.response.result.fileName;
                    input.startTime=moment(this.attCycleStartTime);
                    input.endTime=moment(this.attCycleEndTime);
                    this.loading = true;
                    this._empSalaryItemsServiceProxy
                        .importPersonTax(input)
                        .finally(() => {
                            this.loading = false
                        })
                        .then(res => {
                            if(!res.isSuccess&&res.failedCount>0){
                                this.$notification["warn"]({
                                    message: "总条数:"+res.totalCount+"  失败行数:"+res.failedCount,
                                });
                                this._fileDownloadService.downloadTempFile(res.file);
                                this.success(true);
                            }else {
                                this.$notification["success"]({
                                    message: this.l("导入成功"),
                                });
                                this.success(true);
                            }
                        })
                }
            },
            ExportTemplate(){
                this.loading=true;
                this._empSalaryItemsServiceProxy.exportPersonTaxTemplate()
                    .finally(()=>{this.loading=false})
                    .then(res=>{
                        this._fileDownloadService.downloadTempFile(res);
                    })
            },
        }
    }
</script>

<style scoped>
    .text{
        padding: 5px 0px;
    }
</style>
